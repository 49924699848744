<template>
  <div>
    <v-card flat class="mt-5">
      <v-toolbar dense elevation="0" v-if="$vuetify.breakpoint.name != 'xs'">
        <v-autocomplete
          label="Filter by Location"
          dense
          outlined
          item-text="location_name"
          item-value="location_id"
          :disabled="isLoading == true"
          style="max-width: 200px"
          :items="locationItems"
          @change="get_scanlogs_pagination()"
          v-model="LocationData"
          class="mt-5 mr-2"
        ></v-autocomplete>

        <v-dialog
          ref="dialog1"
          v-model="modal"
          color="#f4813f"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              dense
              outlined
              class="mt-6 ml-2"
              persistent-hint
              :disabled="isLoading == true"
              label="From Date"
              color="#f4813f"
              append-icon="mdi-calendar"
              style="max-width: 150px"
              @click:append="date ? getcal() : getcal()"
              v-bind="attrs"
              v-on="on"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            :min="minmonth"
            :max="new Date().toISOString().substr(0, 10)"
            v-model="date"
            color="#f4813f"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="#f4813f" @click="modal = false"> Cancel </v-btn>
            <v-btn
              v-on:click="get_scanlogs_pagination()"
              text
              color="#f4813f"
              @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          ref="dialog"
          v-model="endmodal"
          color="#f4813f"
          :return-value.sync="todate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatted"
              :disabled="isLoading == true"
              dense
              outlined
              class="mt-6 ml-2"
              persistent-hint
              label="To Date"
              color="#f4813f"
              append-icon="mdi-calendar"
              style="max-width: 150px"
              @click:append="todate ? gettimecal() : gettimecal()"
              v-bind="attrs"
              v-on="on"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            :max="new Date().toISOString().substr(0, 10)"
            :min="date"
            v-model="todate"
            color="#f4813f"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="#f4813f" @click="endmodal = false">
              Cancel
            </v-btn>
            <v-btn
              v-on:click="get_scanlogs_pagination()"
              text
              color="#f4813f"
              @click="$refs.dialog.save(todate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-text-field
          v-model="search"
          label="Search"
          placeholder="Min 3 Character"
          dense
          append-icon="mdi-magnify"
          style="max-width: 220px"
          class="mt-5 ml-2"
        ></v-text-field>
      </v-toolbar>
      <v-row no-gutters v-if="$vuetify.breakpoint.name == 'xs'">
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-toolbar dense elevation="0">
            <v-autocomplete
              label="Filter by Location"
              dense
              outlined
              item-text="location_name"
              item-value="location_id"
              :disabled="isLoading == true"
              :items="locationItems"
              @change="get_scanlogs_pagination()"
              v-model="LocationData"
              class="mt-5 mr-2"
            ></v-autocomplete>
            <v-dialog
              ref="dialog1"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-6 ml-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="From Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-on:click="get_scanlogs_pagination()"
                  text
                  color="#f4813f"
                  @click="$refs.dialog1.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-toolbar dense elevation="0">
            <v-dialog
              ref="dialog"
              v-model="endmodal"
              color="#f4813f"
              :return-value.sync="todate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataFormatted"
                  :disabled="isLoading == true"
                  dense
                  outlined
                  class="mt-6"
                  persistent-hint
                  label="To Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  @click:append="todate ? gettimecal() : gettimecal()"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                :min="date"
                v-model="todate"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="endmodal = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-on:click="get_scanlogs_pagination()"
                  text
                  color="#f4813f"
                  @click="$refs.dialog.save(todate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="search"
              label="Search"
              placeholder="Min 3 Character"
              dense
              append-icon="mdi-magnify"
              class="mt-5 ml-2"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :height="height"
          :headers="scanLogsData"
          :items="ScanLogsItems"
          dense
          :search="search"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :options.sync="pagination"
          @update:options="get_swipes()"
          :fixed-header="fixed"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`item.is_inavlid_swipe`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  :class="
                    item.unknown_swipes != true ? 'green--text' : 'red--text'
                  "
                  >{{
                    item.unknown_swipes != true ? "mdi-check" : "mdi-close"
                  }}</v-icon
                >
                <v-icon
                  v-on="on"
                  small
                  :class="!item.is_dummy_swipe ? 'green--text' : 'red--text'"
                  >{{
                    !item.is_dummy_swipe ? "mdi-check" : "mdi-close"
                  }}</v-icon
                >
              </template>
              <span class="white--text">{{
                item.unknown_swipes == true ? "Invalid" : "Valid"
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No swipes registered yet.</v-alert
            >
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.swipe_date`]="{ item }">
            <span v-text="get_date(item)"></span>
          </template>
          <template v-slot:[`item.swipe_time`]="{ item }">
            <span v-text="get_time(item).split(',')[1]"></span>
          </template>
          <template v-slot:[`item.contact_number`]="{ item }">
            <span
              v-text="
                `${
                  item.user_country_code != null ||
                  item.user_country_code != undefined
                    ? item.user_country_code
                    : ''
                }-${
                  item.user_contact_number != null ||
                  item.user_contact_number != undefined
                    ? item.user_contact_number
                    : ''
                }`
              "
            ></span>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  v-on="on"
                  :color="
                    item.source == 'CAMERA'
                      ? '#757575'
                      : item.source == 'WHATSAPP'
                      ? '#757575'
                      : item.source == 'RESWIPE'
                      ? '#757575'
                      : item.source == 'DATACORRECTION'
                      ? '#757575'
                      : item.source == 'PROXY'
                      ? '#757575'
                      : item.source == 'BEACON'
                      ? '#757575'
                      : item.source == 'BOX'
                      ? '#757575'
                      : item.source == 'LIVE'
                      ? '#757575'
                      : item.source == 'FACE'
                      ? '#757575'
                      : item.source == 'SIMPLE'
                      ? '#757575'
                      : ''
                  "
                  >{{
                    item.source == "CAMERA"
                      ? "mdi-camera"
                      : item.source == "WHATSAPP"
                      ? "mdi-whatsapp"
                      : item.source == "RESWIPE"
                      ? "mdi-redo-variant"
                      : item.source == "DATACORRECTION"
                      ? "mdi-human-edit"
                      : item.source == "PROXY"
                      ? "mdi-odnoklassniki"
                      : item.source == "BEACON"
                      ? "mdi-bluetooth-audio"
                      : item.source == "BOX"
                      ? "mdi-card-account-details-outline"
                      : item.source == "FACE"
                      ? "mdi-face-recognition"
                      : item.source == "DESKTOP"
                      ? "mdi-desktop-classic"
                      : item.source == "LIVE"
                      ? "mdi-map-marker-account"
                      : item.source == "RFID"
                      ? "mdi-card-account-details-outline"
                      : item.source == "SIMPLE"
                      ? "mdi-line-scan"
                      : ""
                  }}</v-icon
                >
              </template>
              <span class="white--text">
                {{
                  item.source == "CAMERA"
                    ? "Source: QR"
                    : item.source == "WHATSAPP"
                    ? "Source: Whatsapp"
                    : item.source == "RESWIPE"
                    ? "Source: Re-Scan"
                    : item.source == "DATACORRECTION"
                    ? "Source: Data Correction"
                    : item.source == "PROXY"
                    ? "Source: PROXY"
                    : item.source == "BEACON"
                    ? "Source: BEACON"
                    : item.source == "BOX"
                    ? "Source: BOX"
                    : item.source == "FACE"
                    ? "Source: FACE"
                    : item.source == "DESKTOP"
                    ? "Source: DESKTOP"
                    : item.source == "LIVE"
                    ? "Source: Live"
                    : item.source == "RFID"
                    ? "Source: RFID"
                    : item.source == "SIMPLE"
                    ? "Source: SIMPLE"
                    : ""
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.geo`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  @click="
                    item.user_lat != undefined ||
                    item.user_long != undefined ||
                    item.user_lat != null ||
                    item.user_long != null
                      ? view_user_location(item)
                      : ''
                  "
                >
                  <v-icon
                    v-on="on"
                    small
                    :color="
                      item.user_lat != undefined ||
                      item.user_long != undefined ||
                      item.user_lat != null ||
                      item.user_long != null
                        ? 'primary'
                        : ''
                    "
                    >{{
                      item.user_lat != undefined ||
                      item.user_long != undefined ||
                      item.user_lat != null ||
                      item.user_long != null
                        ? "mdi-map-marker"
                        : "mdi-map-marker-off"
                    }}</v-icon
                  >
                </a>
              </template>
              <span
                class="white--text"
                v-if="
                  item.user_lat != undefined ||
                  item.user_long != undefined ||
                  item.user_lat != null ||
                  item.user_long != null
                "
                >View Geo</span
              >
              <span class="white--text" v-else> Geo not available</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.box_location`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.box_location }}</div>
              </template>
              <span class="white--text">{{ item.box_location }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div
                  v-if="item.unknown_swipes == true"
                  class="overflow ml-n4"
                  v-on="on"
                >
                  <v-icon color="red" class="ml-0" x-small>
                    mdi-close-thick
                  </v-icon>
                  {{ item.user_name }}
                </div>
                <div v-else class="ml-0">
                  {{ item.user_name }}
                </div>
              </template>
              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.forms`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    dense
                    v-on="on"
                    depressed
                    color="primary"
                    medium
                    @click="proof_ofprezence(item)"
                    class="ml-4"
                    :disabled="item.forms == null"
                  >
                    mdi-information-slab-circle-outline
                  </v-icon>
                </div>
              </template>

              <span
                v-if="item.forms != undefined && item.forms != null"
                class="white--text"
              >
                Proof of Work</span
              >
              <span v-else class="white--text">No Forms</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <ViewGeoSwipes
      :viewGeoSwipesDialog="viewGeoSwipesDialog"
      @close_view_geo_swipes_dialog="viewGeoSwipesDialog = false"
      :userObject="userObject"
      :swipeData="swipeData"
    />
    <ProofWork
      :work_Proof="work_Proof"
      @clicked="close_prop"
      :userObject="userObject"
    />
  </div>
</template>

<script>
import moment from "moment";
import ViewGeoSwipes from "@/components/Dialogs/ViewGeoSwipes.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetSwipesForOrganisations } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import ProofWork from "../Dialogs/ProofWork.vue";
import { GetLocation } from "@/mixins/GetLocationList.js";

export default {
  components: {
    Overlay,
    ViewGeoSwipes,
    ProofWork,
  },
  mixins: [GetLocation],
  data: () => ({
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    fixed: true,
    modal: false,
    work_Proof: false,
    endmodal: false,
    next_token: null,
    swipeData: [],
    overlay: false,
    LocationData: "ALL",
    locationItems: [],
    search: "",
    scanLogsData: [
      { text: "", value: "type" },
      { text: "Location Name", value: "box_location" },
      { text: "Member Name", value: "user_name" },
      { text: "Contact Number", value: "contact_number" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Swipe Time", value: "swipe_time" },
      { text: "Geo", value: "geo" },
      { text: "Proof of Work", value: "forms" },
    ],
    viewGeoSwipesDialog: false,
    ScanLogsItems: [],
    height: 0,
    userObject: {},
    isLoading: false,
    date: new Date().toISOString().substr(0, 10),
    todate: new Date().toISOString().substr(0, 10),
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  async created() {
    this.height = window.innerHeight - 210;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    await this.GetLocation();
    await this.fetch_deatils();
    await this.get_swipes();
    // console.log(this.date);
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    search() {
      if (this.search != "") {
        if (this.search.length >= 3) {
          // console.log(this.search);
          this.get_swipes();
        }
      } else {
        this.get_swipes();
      }
    },
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_scanlogs_pagination() {
      this.next_token = null;
      this.ScanLogsItems = [];
      this.get_swipes();
    },
    fetch_deatils() {
      this.GetLocationitems.forEach((element) => {
        this.locationItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.locationItems.unshift("ALL");
    },
    gettimecal() {
      this.endmodal = true;
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    close_prop(val) {
      if (val == 0) {
        this.work_Proof = false;
      }
    },
    proof_ofprezence(item) {
      this.work_Proof = true;
      this.userObject = item;
      // console.log(item);
    },
    get_date(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
          // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );
      const [month, day, year] = a.split("/");

      // Reformatting to "day month year" format
      return `${day}/${month}/${year}`;
    },
    get_time(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
      return a.toUpperCase();
    },
    view_user_location(item) {
      this.userObject = [];
      this.swipeData = [];
      this.userObject = item;
      this.swipeData.push({
        position: {
          lat: Number(item.user_lat),
          lng: Number(item.user_long),
        },
      });
      // console.log("swipeData", this.swipeData),
      this.viewGeoSwipesDialog = true;
    },

    async get_swipes() {
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(GetSwipesForOrganisations, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              nextToken: this.next_token,
              swipe_type_queries: "SWIPES",
              limit: this.search.length >= 3 ? 1000 : 30,
              location_id: this.LocationData,
              from_date: this.date,
              to_date: this.todate,
              members_name: this.search,
            },
          })
        ).then((res) => {
          var response = res.data.GetSwipesForOrganisations.data
            ? res.data.GetSwipesForOrganisations.data
            : [];
          let array = this.ScanLogsItems.concat(response);
          this.ScanLogsItems = array;
          const uniqueArrayOfObjects = this.ScanLogsItems.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.swipe_id === obj.swipe_id)
          );
          this.ScanLogsItems = uniqueArrayOfObjects;
          // console.log(this.ScanLogsItems, "this.ScanLogsItems");

          this.next_token = res.data.GetSwipesForOrganisations.nextToken;
          this.$forceUpdate();
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        this.overlay = false;
        this.isLoading = false;
        this.ScanLogsItems = [];
      }
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
/* .v-application--is-ltr .v-data-footer__pagination {
  margin-left: auto;
} */
</style>
