<template>
  <div>
    <v-dialog v-model="work_Proof" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0" height="80px"
          ><v-toolbar-title class="white--text"
            >Proof of Work <br />
            {{ this.userObject.user_name }}</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n2"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
          v-if="userObject.forms.is_free_text == true"
        >
          <!-- true -->
          <v-text-field
            label="Working Type"
            v-model="worktype"
            dense
            readonly
            outlined
            class="mt-4 mr-4 ml-2"
          ></v-text-field>
          <v-textarea
            label="Work Notes"
            v-model="comment_work"
            dense
            readonly
            outlined
            class="mt-n2 mr-4 ml-2"
          ></v-textarea>
          <div
            style="border: 1px solid gray; width: fit-content"
            class="ml-2"
            v-if="images == null"
          >
            <v-img height="145" width="320"
              ><v-container class="fill-height d-flex justify-center" fluid>
                <span>No - Image</span>
              </v-container>
            </v-img>
          </div>
          <div v-if="images != null">
            <v-card max-width="320" class="ml-2" flat>
              <v-carousel
                v-model="model"
                height="200px"
                hide-delimiters
                :show-arrows="false"
                v-if="images.length == 1"
              >
                <v-carousel-item
                  v-for="images1 in images"
                  :key="images1"
                  :src="images1"
                >
                </v-carousel-item>
              </v-carousel>
              <v-carousel
                v-model="model"
                height="200px"
                width="100px"
                hide-delimiters
                v-else
              >
                <v-carousel-item
                  v-for="images1 in images"
                  :key="images1"
                  :src="images1"
                >
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-text-field
            label="Working Type"
            v-model="worktype"
            dense
            readonly
            outlined
            class="mt-4 mr-10 ml-4"
          ></v-text-field>
          <v-row no-gutters>
            <v-col
              v-for="(index, Idx) in formFields"
              :key="Idx"
              :cols="Idx === 0 ? '12' : '12 mt-n3'"
              sm="12"
              xs="12"
              md="12"
            >
              <TextField
                :formActionType="formActionType"
                v-if="
                  index.type.toUpperCase() == 'TEXTFIELD' ||
                  index.type.toUpperCase() == 'EMAIL' ||
                  index.type.toUpperCase() == 'NAME' ||
                  index.type.toUpperCase() == 'MOBILENUMBER' ||
                  index.type.toUpperCase() == 'STRING'
                  ||index.type.toUpperCase() == 'NUMBER'||index.type.toUpperCase() == 'DROPDOWN'
                  || index.type.toUpperCase() == 'TIME' || index.type.toUpperCase()=='SINGLE_CHOICE'
                  || index.type.toUpperCase() == 'DATE'
                "
                :index="index"
              />
              <!-- <NumberField
                v-if="index.type.toUpperCase() == 'NUMBER'"
                :index="index"
                :formActionType="formActionType"
              /> -->
              <CheckboxField
                class="mt-n4"
                v-if="index.type.toUpperCase() == 'CHECKBOX'"
                :index="index"
                :formActionType="formActionType"
              />
              <TextAreaField
                v-if="index.type.toUpperCase() == 'PARAGRAPH'"
                :index="index"
                :formActionType="formActionType"
              />
              <LabelField
              v-if="index.type.toUpperCase() == 'LABEL'"
              :index="index"
              :formActionType="formActionType"
            />
              <!-- <DateField
                v-if="index.type.toUpperCase() == 'DATE'"
                :index="index"
                :formActionType="formActionType"
              /> -->
              <!-- <DropdowmField
                v-if="index.type.toUpperCase() == 'DROPDOWN'"
                :index="index"
                :formActionType="formActionType"
              /> -->
              <!-- <TimePicker
                v-if="index.type.toUpperCase() == 'TIME'"
                :index="index"
                :formActionType="formActionType"
              /> -->
              <RatingFile
                class="mt-n4"
                v-if="index.type.toUpperCase() == 'RATING'"
                :index="index"
                :formActionType="formActionType"
              />
              <DividerComp
                v-if="index.type.toUpperCase() == 'DIVIDER'"
                :index="index"
                :formActionType="formActionType"
              />
              <FlipSwitch
                v-if="index.type.toUpperCase() == 'FLIPSWITCH'
                || index.type.toUpperCase()=='BOOLEAN'"
                :index="index"
                class="mt-n4"
                :formActionType="formActionType"
              />
            </v-col>
          </v-row>
          <div
            style="border: 1px solid gray; width: fit-content"
            class="ml-4"
            v-if="images == null"
          >
            <v-img height="145" width="290"
              ><v-container class="fill-height d-flex justify-center" fluid>
                <span>No - Image</span>
              </v-container>
            </v-img>
          </div>
          <div v-if="images != null">
            <v-card max-width="320" class="ml-2" flat>
              <v-carousel
                v-model="model"
                height="200px"
                hide-delimiters
                :show-arrows="false"
                v-if="images.length == 1"
              >
                <v-carousel-item
                  v-for="images1 in images"
                  :key="images1"
                  :src="images1"
                  class="mr-8 ml-3"
                >
                </v-carousel-item>
              </v-carousel>
              <v-carousel
                v-model="model"
                height="200px"
                class="ml-n1 mr-16"
                hide-delimiters
                v-else
              >
                <v-carousel-item
                  v-for="images1 in images"
                  :key="images1"
                  :src="images1"
                  class="ml-2 mr-4"
                >
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextField from "@/components/FormDesigner/FormFields/TextField.vue";
// import NumberField from "@/components/FormDesigner/FormFields/NumberField.vue";
import CheckboxField from "@/components/FormDesigner/FormFields/CheckboxField.vue";
import TextAreaField from "@/components/FormDesigner/FormFields/TextAreaField.vue";
// import DateField from "@/components/FormDesigner/FormFields/DateField.vue";
// import DropdowmField from "@/components/FormDesigner/FormFields/DropdowmField.vue";
// import TimePicker from "@/components/FormDesigner/FormFields/TimePicker.vue";
import RatingFile from "@/components/FormDesigner/FormFields/RatingFile.vue";
import DividerComp from "@/components/FormDesigner/FormFields/DividerComp.vue";
import FlipSwitch from "@/components/FormDesigner/FormFields/FlipSwitch.vue";
import LabelField from "@/components/FormDesigner/FormFields/LabelField.vue";
export default {
  props: {
    work_Proof: Boolean,
    userObject: Object,
  },
  components: {
    TextField,
    // NumberField,
    CheckboxField,
    TextAreaField,
    // DateField,
    // DropdowmField,
    // TimePicker,
    RatingFile,
    DividerComp,
    FlipSwitch,
    LabelField,
  },
  data: () => ({
    worktype: "",
    model: 0,
    comment_work: "",
    formActionType: "previewproof",
    images: "",
    formFields: [],
  }),
  watch: {
    work_Proof: {
      handler() {
        this.formFields=[];
        this.worktype = this.userObject.forms.working_type;
        this.comment_work = this.userObject.forms.working_comments;
        this.images = this.userObject.forms.working_field_keys;
        // console.log(this.userObject.forms.is_free_text);
        this.formFields = this.userObject.forms.form_template;

        this.formFields = this.formFields.map((x) => {
          return {
            type: x.data_type,
            value: x.data_type == 'rating' ? +x.value : x.value,
            displayLabel: x.display_label,
            ...x,
          };
        });
        // (this.formFields = [
        //   {
        //     __typename: "template",
        //     data_type: "string",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "string",
        //     value: "Test",
        //     value_label: "type",
        //   },
        //   {
        //     __typename: "template",
        //     data_type: "boolean",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "CHECKBOX",
        //     value: true,
        //     value_label: "type",
        //   },
        //   {
        //     __typename: "template",
        //     data_type: "string",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "NUMBER",
        //     value: 25,
        //     value_label: "type",
        //   },
        //   {
        //     __typename: "template",
        //     data_type: "string",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "PARAGRAPH",
        //     value:
        //       "Vuetify is a complete UI framework built on top of Vue.js. The goal of the project is to provide developers with the tools they need to build rich and engaging user experiences. Unlike other frameworks, Vuetify is designed from the ground up to be easy to learn and rewarding to master with hundreds of carefully crafted components from the ",
        //     value_label: "type",
        //   },
        //   {
        //     __typename: "template",
        //     data_type: "string",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "DATE",
        //     value: "23/06/2000",
        //     value_label: "type",
        //   },
        //   {
        //     __typename: "template",
        //     data_type: "Ratings",
        //     displayLabel: "Name",
        //     display_label: "Name",
        //     display_weightage: "100",
        //     input_array_value: null,
        //     is_displayed: true,
        //     is_readonly: false,
        //     is_required: true,
        //     key: "name",
        //     section: null,
        //     type: "RATING",
        //     value: 4,
        //     value_label: "type",
        //   },
        // ]),
          // console.log(this.formFields);
        // this.url = this.userObject.forms.working_field_keys;
        // console.log(this.userObject.forms);
        // console.log(this.images);
      },
    },
  },

  methods: {
    close_dialog() {
      this.formFields=[];
      // console.log(this.userObject.forms, "userObject");
      this.$emit("clicked", 0);
    },
  },
};
</script>

<style>
</style>